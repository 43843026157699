import { FC, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'urql';
import { orderBy } from 'lodash';
import Dialog from '@mui/material/Dialog';
import { useLocation } from 'react-router-dom';
import { usePausedContext } from '@coUrbanize/community-modules';
import useUpdate from '../components/features/updates/useUpdate';
import UpdateRow from '../components/features/updates/UpdateRow';
import UpdateTemplates from '../components/features/updates/UpdateTemplates';
import UpdateEditor from '../components/features/updates/UpdateEditor';
import PageHeader from 'components/common/PageHeader';
import ButtonLink from 'components/common/ButtonLink';
import { useDeleteActionConfirmation } from 'hooks/useActionConfirmation';
import RegularButton from 'components/common/styled/RegularButton';
import { projectSlugQuery } from 'api/query/project.queries';
import { incorporatedCommentTemplate } from '../components/features/updates/templates';

const Updates: FC = () => {
	const {
		updates,
		handleDelete,
		projectId,
		openTemplates,
		setOpenTemplates,
		openEditor,
		setOpenEditor,
		currentTemplate,
		setCurrentTemplate,
		editorState,
		setEditorState,
		handleTitleUpdate,
		handleSave,
		errors,
		addImage,
	} = useUpdate();

	const { isPaused } = usePausedContext();

	const showDeleteConfirmation = useDeleteActionConfirmation();

	const onDelete = async (updateId: number, title: string) => {
		await showDeleteConfirmation(
			{
				title: 'Delete Update',
				message: `Are you sure you want to delete ${title} update?`,
			},
			() => handleDelete(updateId),
		);
	};

	const separatedUpdates = orderBy(
		updates.filter((update) => !update.published),
		'created',
		'desc',
	).concat(
		orderBy(
			updates.filter((update) => update.published),
			'published',
			'desc',
		),
	);

	const [{ data: projectData }] = useQuery({
		query: projectSlugQuery(),
		variables: { projectId },
	});

	const projectName = projectData?.project?.slug;
	const isCustomUrl = Boolean(projectData?.project?.customUrlEnabledDate);
	const customUrl = projectData?.project?.customUrl;

	const location = useLocation();

	//if the route is coming from the comments page where a use selected a comment to incorporate, open up the custom template
	useEffect(() => {
		if (location.state && location.state.comment) {
			const template = incorporatedCommentTemplate(location.state.comment);
			setCurrentTemplate(template);
			setEditorState(template.richText);
			setOpenEditor(true);
		}
	}, [location, location.state, setCurrentTemplate, setEditorState, setOpenEditor]);

	return (
		<Box p={2} px={5} maxWidth={926}>
			<PageHeader>Updates</PageHeader>
			<Typography my={2}>
				Updates are the one of the best ways to engage existing project
				followers. When you post an update, you have the option of sending it
				via email to followers.
			</Typography>

			<Typography mb={2}>
				We recommend posting at least one Update every month throughout the
				project life cycle.
			</Typography>

			<ButtonLink
				link="https://fast.wistia.com/embed/channel/7nj7jkmpbx?wchannelid=7nj7jkmpbx&wmediaid=ayxt9dqknz"
				label="Learn Why Posting Updates is So Important"
			/>

			<RegularButton
				variant="contained"
				size="large"
				style={{
					textTransform: 'none',
					fontWeight: 500,
					fontSize: 18,
					padding: '12px 44px',
				}}
				disabled={!!isPaused}
				onClick={() => setOpenTemplates(true)}
			>
				New Update
			</RegularButton>
			<Dialog
				onClose={() => setOpenTemplates(false)}
				open={openTemplates}
				maxWidth="md"
			>
				<Box p={3} pt={2}>
					<UpdateTemplates
						projectId={projectId}
						onClose={() => setOpenTemplates(false)}
						onSelect={(template) => {
							setCurrentTemplate(template);
							setOpenTemplates(false);
							setOpenEditor(true);
						}}
					/>
					{updates.length > 0 && (
						<Typography fontWeight="800" fontSize={20} mb={2}>
							OR Copy Existing Update
						</Typography>
					)}
					{separatedUpdates.map((update, i) => (
						<UpdateRow
							key={update.id}
							update={update}
							onDelete={onDelete}
							disabled={openEditor}
							index={updates.length - i}
							copy={true}
							projectName={projectName}
							onEdit={() => {
								const newTemplate = {
									id: 0,
									title: update.title,
									richText: update.richText!,
								};
								setOpenTemplates(false);
								setCurrentTemplate(newTemplate);
								setOpenEditor(true);
							}}
						/>
					))}
				</Box>
			</Dialog>

			<div id="editor" />

			<Dialog
				onClose={() => setOpenEditor(false)}
				open={openEditor}
				maxWidth="md"
			>
				<UpdateEditor
					editorState={editorState}
					setEditorState={setEditorState}
					handleTitleUpdate={handleTitleUpdate}
					onClose={() => setOpenEditor(false)}
					title={currentTemplate.title}
					onSave={handleSave}
					published={currentTemplate.published}
					errors={errors}
					isNewUpdate={currentTemplate.id === 0}
					addImage={addImage}
				// editorInstance={editorInstance}
				/>
			</Dialog>

			<Box pt={5}>
				{separatedUpdates.map((update, i) => (
					<UpdateRow
						key={update.id}
						update={update}
						onDelete={onDelete}
						disabled={openEditor || isPaused}
						index={updates.length - i}
						copy={false}
						projectName={projectName}
						customUrl={customUrl}
						isCustomUrl={isCustomUrl}
						onEdit={() => {
							setCurrentTemplate(update as any);
							setOpenEditor(true);
						}}
					/>
				))}
			</Box>
		</Box>
	);
};

export default Updates;
