import { gql } from 'urql';

export const pagesQuery = gql`
	query ($projectId: Int!) {
		pagesByProject(projectId: $projectId) {
			id
			name
			ordinal
			externalUrlOverride
			slug
			project {
				id
				name
				type
				slug
				customUrl
				customUrlEnabledDate
			}
		}
	}
`;
