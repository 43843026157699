import React, { FC } from 'react';
import { Box, IconButton, Pagination, Typography, Theme } from '@mui/material';
import SearchInput from 'components/common/SearchInput';
import DropDown from 'components/common/DropDown';
import Filters from 'components/features/commentsPage/Filters';
import CommentNav from 'components/features/commentsPage/CommentNav';
import { detNavItemsCount } from 'utility/commentUtils';
import ParentComment from 'components/features/commentsPage/ParentComment';
import { Close as CloseIcon } from '@mui/icons-material';
import { pageLimit } from 'components/features/commentsPage/useComments';
import { sortByList, Filter, defaultFilter } from '../../utility/commentData';
import PageHeader from 'components/common/PageHeader';
import User from 'types/user';
import { Comment } from 'types/types';
import { SeparatedComment } from '../../types/separatedComment';
import _ from 'lodash';

type CommentsViewProps = {
	projectId: number;
	user: User | null;
	theme: Theme;
	separatedComments: SeparatedComment[];
	paginatedComments: SeparatedComment[];
	search: string;
	filter: Filter;
	pageNumber: number;
	handleSearch: (value: string) => void;
	handleFilter: (value: Filter) => void;
	setSortBy: React.Dispatch<React.SetStateAction<string>>;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	addComment: (comment: Comment) => void;
	saveComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number>;
	editComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number | undefined>;
	likeComment: (id: number) => Promise<void>;
	moderateComment: (
		comment: Comment,
		cancel?: boolean | undefined,
	) => Promise<number>;
	disabled?: boolean;
	semiFilteredSeparatedComments: SeparatedComment[];
};

const CommentsView: FC<CommentsViewProps> = ({
	projectId,
	user,
	theme,
	separatedComments,
	paginatedComments,
	search,
	filter,
	pageNumber,
	handleSearch,
	handleFilter,
	setSortBy,
	setPageNumber,
	addComment,
	saveComment,
	editComment,
	likeComment,
	moderateComment,
	disabled,
	semiFilteredSeparatedComments,
}) => {
	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<PageHeader>Comment Management</PageHeader>

				<Box
					display="flex"
					alignItems="center"
					sx={{ color: theme.customPalette.primaryColors.sky[1] }}
				>
					<Typography fontWeight="bold" fontSize={14} mr={1}>
						Sort by:{' '}
					</Typography>
					<DropDown onChange={setSortBy} items={sortByList} />
					<Filters
						filter={filter}
						onFilter={handleFilter}
						projectId={projectId}
					/>
				</Box>
			</Box>
			<SearchInput label="Search comments" fullWidth onSearch={handleSearch} />

			<CommentNav
				sx={{ mt: 2 }}
				navItems={detNavItemsCount(semiFilteredSeparatedComments)}
				active={filter.selectedTab}
				onNavigate={(active: string) =>
					handleFilter({ ...filter, selectedTab: active })
				}
			/>

			{search.length > 0 && (
				<Box mt={2} display="flex" gap={1} alignItems="center">
					<Typography color="secondary" fontWeight="bold">
						Searching:{' '}
					</Typography>
					<Box
						display="flex"
						alignItems="center"
						bgcolor={theme.customPalette.surface.dark}
						borderRadius="12px"
						py={0.1}
						px={0.8}
						color="#004057"
						fontWeight="bold"
					>
						{search
							.trim()
							.toLowerCase()
							.split(' and ')
							.map((word, index) => (
								<Typography
									key={index}
									variant="body2"
									sx={{ fontSize: '16px', marginLeft: '5px' }}
								>
									<strong>
										{word.split(' ').map((spacedWord, swIndex) => {
											if (
												search.toLowerCase().split(' and ').length <= 1 &&
												swIndex < word.split(' ').length - 1
											) {
												return spacedWord.toLowerCase() + ', ';
											} else {
												return spacedWord.toLowerCase() + ' ';
											}
										})}
									</strong>
									{index !== search.toLowerCase().split(' and ').length - 1 && (
										<span> and </span>
									)}
								</Typography>
							))}
						<IconButton size="small" onClick={() => handleSearch('')}>
							<CloseIcon sx={{ fontSize: 18 }} />
						</IconButton>
					</Box>
				</Box>
			)}

			<Box mt={5}>
				{paginatedComments.map((comment) => (
					<ParentComment
						key={comment.parentComment.id}
						user={user}
						comment={comment.parentComment}
						childComments={comment.childComments}
						filter={filter}
						addComment={addComment}
						saveComment={saveComment}
						editComment={editComment}
						likeComment={likeComment}
						moderateComment={moderateComment}
						disabled={disabled}
						showAllComments={
							!_.isEqual(filter, defaultFilter) || search.trim().length > 0
						}
					/>
				))}
			</Box>

			{separatedComments.length > pageLimit && (
				<>
					<Pagination
						page={pageNumber}
						count={Math.ceil(separatedComments.length / pageLimit)}
						variant="outlined"
						shape="rounded"
						sx={{ mt: 5 }}
						onChange={(e, pageNumber) => setPageNumber(pageNumber)}
					/>
					<Typography color="secondary" fontWeight="bold" mt={1}>
						Viewing {paginatedComments.length} of {separatedComments.length}{' '}
						comments
					</Typography>
				</>
			)}
		</>
	);
};

export default CommentsView;
