import React, { useEffect, useState } from 'react';
import {
	SettingsOutlined as SettingsIcon,
	PieChart,
	Update as UpdateIcon,
	RateReview as RateReviewIcon,
	Help as HelpIcon,
} from '@mui/icons-material';
import { useQuery } from 'urql';
import { pagesQuery } from 'api/query/page.queries';
import MenuItem, { itemData } from './MenuItem';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Paper,
	useTheme,
} from '@mui/material';
import SelectedMenu, { defaultProjectToken } from '../SelectedMenu';
import { useParams } from 'react-router-dom';

interface LeftMenuProps {
	projects: { id: number; name: string; slug: string }[];
	userProjects: { id: number; name: string }[];
	activeProject: number;
	setActiveProject: (val: number) => void;
}

const menuFalseStates = {
	'Project Info': false,
	Feedback: false,
};

const LeftMenu: React.FC<LeftMenuProps> = ({
	projects,
	activeProject,
	setActiveProject,
	userProjects,
}) => {
	const theme = useTheme();
	const { projectId: selectedProjectId } = useParams();

	const defaultProjectId = localStorage.getItem(defaultProjectToken);
	// when a user comes to the home page, there are no params,
	// so we need to set the default project to their active project
	const projectId = selectedProjectId ?? defaultProjectId ?? activeProject;

	const selectedProject = projects
		? activeProject && projectId
			? projects.filter((x) => x.id === Number(projectId))[0]
			: projects[0]
		: null;

	const id: number = selectedProject?.id || 1;

	const [{ data }] = useQuery({
		query: pagesQuery,
		variables: { projectId: id },
	});
	const pageId = data?.pagesByProject[0].id;

	const [projectsMenu, setProjectsMenu] = useState<itemData>();
	const [menuItems, setMenuItems] = useState<itemData[]>([]);
	const [menuStates, setMenuStates] = useState<any>(menuFalseStates);
	const [projectMenuOpen, setProjectMenuOpen] = useState(false);

	const setMenuOpen = (name: string, v: boolean) => {
		setProjectMenuOpen(false);
		setMenuStates({ ...menuFalseStates, [name]: v });
	};

	const setProjectMenu = (open: boolean) => {
		setProjectMenuOpen(open);
		setMenuStates({ ...menuFalseStates });
	};

	useEffect(() => {
		if (selectedProject && projects && pageId && id) {
			const projectsMenuData: itemData = {
				name: selectedProject?.name,
				to: '#',
				isOpen: true,
				subItem: [
					...userProjects?.map((project) => ({
						name: project.name,
						to: `/projects/${project.id}`,
						id: project.id,
					})),
				],
			};

			const menu: itemData[] = [
				{
					name: 'Project Info',
					to: '#',
					Icon: SettingsIcon,
					subItem: [
						{
							name: 'Project Details',
							to: `/projects/${id}/details`,
						},
						{
							name: 'Design',
							to: `/projects/${id}/pages/${pageId}`,
						},
						{
							name: 'Timeline',
							to: `/projects/${id}/timeline`,
						},
						{
							name: 'Public Benefits',
							to: `/projects/${id}/public-benefits`,
						},
						{
							name: 'Information & Plans',
							to: `/projects/${id}/information-plans`,
						},
					],
				},
				{
					name: 'Feedback',
					to: `/projects/${id}/feedback`,
					Icon: RateReviewIcon,
					subItem: [
						{
							name: 'Questions',
							to: `/projects/${id}/questions`,
						},
						{
							name: 'Comments',
							to: `/projects/${id}/comments`,
						},
						{
							name: 'Form Responses',
							to: `/projects/${id}/responses`,
						},
					],
				},
				{ name: 'Updates', to: `/projects/${id}/updates`, Icon: UpdateIcon },
				{
					name: 'Analytics',
					to: `/projects/${id}/analytics`,
					Icon: () => <PieChart />,
				},
			];
			setMenuItems(menu);
			setProjectsMenu(projectsMenuData);
		}
	}, [selectedProject, projects, id, pageId, userProjects]);
	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overflow: 'auto',
				width: 290,
				'&::-webkit-scrollbar': {
					width: 7,
				},
				'&::-webkit-scrollbar-track': {
					borderRadius: 10,
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: 10,
					backgroundColor: theme.customPalette.primaryColors.grassGreen[1],
				},
			}}
		>
			<SelectedMenu
				options={projectsMenu?.subItem ?? []}
				activeProject={activeProject}
				setActiveProject={setActiveProject}
				projects={projects}
				open={projectMenuOpen}
				setOpen={setProjectMenu}
			/>
			<List
				sx={{
					backgroundColor: theme.customPalette.surface.light,
					pl: '0px',
					pr: '12px',
					display: 'flex',
					flexDirection: 'column',
				}}
				component="nav"
				aria-labelledby="nested-list-subheader"
			>
				{menuItems.map((item: itemData, index: number) => (
					<ListItem
						key={index}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						{item.subItem ? (
							<MenuItem
								item={item}
								key={index}
								setActiveProject={setActiveProject}
								open={item.name !== undefined ? menuStates[item.name] : false}
								setOpen={(v) => setMenuOpen(item.name!, v)}
							/>
						) : (
							<MenuItem
								item={item}
								key={index}
								setActiveProject={setActiveProject}
							/>
						)}
					</ListItem>
				))}
			</List>
			<List
				dense={false}
				sx={{ bgColor: 'background.default', display: 'flex' }}
			>
				<ListItem
					component="a"
					href="https://fast.wistia.com/embed/channel/7nj7jkmpbx?wchannelid=7nj7jkmpbx"
					target="_blank"
					sx={{ p: 0 }}
				>
					<ListItemButton>
						<ListItemIcon>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText
							primary="Help and support"
							primaryTypographyProps={{
								color: theme.customPalette.primaryColors.sky[1],
							}}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</Paper>
	);
};

export default LeftMenu;
