import React, { useEffect, useState } from 'react';
import AppDialog from 'components/common/Dialog';
import {
	Checkbox,
	Alert,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	FormControl,
	Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'urql';
import useNumberParams from 'hooks/useNumberParams';
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import _ from 'lodash';
import { produce } from 'immer';
import {
	fileNames,
	initialOptions,
	initQuery,
	projectQuery,
	saveFile,
} from 'utility/exportAnalytics';
import { createAnalyticsExportNotificationMutation } from 'api/mutation/analytics.mutations';
import JSZip from 'jszip';
import { Parser } from 'json2csv';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { FileDownload } from '@mui/icons-material';
import { useSnackBar } from '../../../providers/SnackBarProvider';

interface ExportModalProps {
	open: boolean;
	setOpen: (fn: boolean) => void;
	existData: any;
}

enum ExportFormat {
	PDF = 'PDF',
	CSV = 'CSV',
}

const date = dayjs(new Date()).format('YYYY-MM-DD');

export const ExportModal: React.FC<ExportModalProps> = (props) => {
	const { notify } = useSnackBar();
	const { open, setOpen, existData } = props;

	const [options, setOptions] = useState(initialOptions);
	const [exportFormat, setExportFormat] = useState<ExportFormat>(
		ExportFormat.CSV,
	);
	const [error, setError] = useState('');
	const [graphqlQuery, setGraphqlQuery] = useState(initQuery);
	const [loadingRightButton, setLoadingRightButton] = useState(false);
	const { getAccessTokenSilently: getToken } = useAuth0();

	const [, createAnalyticsExportNotification] = useMutation(
		createAnalyticsExportNotificationMutation,
	);

	const handleChange = (e: any, k: string) => {
		setOptions({
			...options,
			[k]: { ...options[k], checked: e.target.checked },
		});
	};

	const handleFormatChange = (e: any, k: string) => {
		if (exportFormat === ExportFormat.CSV) {
			setOptions(
				produce(options, (draftOptions: any) => {
					for (const k in draftOptions) {
						if (!['comments', 'letters'].includes(k)) {
							draftOptions[k].checked = false;
						} else {
							draftOptions[k].checked = true;
						}
					}
				}),
			);
		} else {
			setOptions(initialOptions);
		}
		setExportFormat((ExportFormat as any)[k]);
	};

	const { projectId } = useNumberParams();

	const [{ data }] = useQuery({
		query: graphqlQuery,
		variables: { projectId },
	});

	const [{ data: project }] = useQuery({
		query: projectQuery,
		variables: { projectId },
	});

	useEffect(() => {
		const query: any = {
			__variables: { projectId: 'Int!' },
			analyticsExport: { __args: { projectId: new VariableType('projectId') } },
		};

		let needQuery = false;

		if (options['comments'].checked) {
			needQuery = true;
			query['analyticsExport']['comments'] = {
				username: true,
				dateTime: true,
				question: true,
				commentResponse: true,
				inResponseTo: true,
			};
		}

		if (options['polls'].checked) {
			needQuery = true;
			query['analyticsExport']['polls'] = {
				username: true,
				dateTime: true,
				question: true,
				response: true,
			};
		}

		if (options['letters'].checked) {
			needQuery = true;
			query['analyticsExport']['letters'] = {
				typeOfForm: true,
				dateTime: true,
				username: true,
				formFieldsResponses: true,
			};
		}
		if (needQuery) {
			setGraphqlQuery(jsonToGraphQLQuery({ query }, { pretty: true }));
		}
	}, [options]);

	const generateReport = async () => {
		setLoadingRightButton(true);

		try {
			if (exportFormat === ExportFormat.PDF) {
				const token = await getToken();
				const response = await fetch(
					`${process.env.REACT_APP_API_URI}/processor/outreach-report/${projectId}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							comments: options['comments'].checked,
							letters: options['letters'].checked,
						}),
					},
				);
				if (response.ok) {
					setOpen(false);
					notify(
						'Outreach report is generating and will be emailed to you when ready.',
					);
				}
				return;
			}
			if (!data) {
				setError('Error While Getting Data !');
				return;
			}

			const zip = new JSZip();

			const addCsvFile = (
				csvKey: string,
				csvData: any,
				postFix: string = '',
			) => {
				if (!csvData) {
					return true;
				}
				const csvDataClean = csvData.map((item: any) => {
					return _.omit(item, ['__typename']);
				});
				const uppercaseCsvHeaders = csvDataClean.map((x: any) => {
					return _.mapKeys(x, (k, key) => _.startCase(key));
				});
				if (uppercaseCsvHeaders.length > 0) {
					const json2csvParser = new Parser();
					const csv = json2csvParser.parse(uppercaseCsvHeaders);
					const name = !postFix
						? fileNames[csvKey]
						: `${fileNames[csvKey]}-${postFix}`;

					zip.file(
						`${project?.project.name.replace(
							/\s+/g,
							'-',
						)}-${name}-${project?.project.launchedDate.substring(
							0,
							10,
						)}--${date}.csv`,
						csv,
					);
				}
			};

			delete data?.analyticsExport['__typename'];

			const { letters, ...otherData } = data?.analyticsExport;

			const { feedbacks, ...otherExistData } = existData;

			if (options['feedbacks']?.checked) {
				for (let csvKey in feedbacks) {
					addCsvFile(csvKey, feedbacks[csvKey]);
				}
			}

			for (let csvKey in otherExistData) {
				if (options[csvKey]?.checked) {
					addCsvFile(csvKey, otherExistData[csvKey]);
				}
			}

			for (let csvKey in otherData) {
				if (options[csvKey].checked) {
					addCsvFile(csvKey, otherData[csvKey]);
				}
			}

			if (letters && options['letters'].checked) {
				const grouped = _.groupBy(
					letters.map((i: any) => {
						return _.omit(
							{ ...i, ...i.formFieldsResponses },
							'formFieldsResponses',
						);
					}),
					(i: any) => i.typeOfForm,
				);
				for (let key in grouped) {
					addCsvFile('letters', grouped[key], key);
				}
			}

			const fields = [];
			for (let key in options) {
				if (options[key].checked) {
					fields.push(options[key].title);
				}
			}
			await createAnalyticsExportNotification({
				fields,
				projectName: project?.project.name,
			});

			let fileName = `${project?.project.name.replace(
				/\s+/g,
				'-',
			)}-analytics-report-${date}.zip`;
			if (JSZip.support.uint8array) {
				await zip.generateAsync({ type: 'uint8array' }).then((blob) => {
					saveFile(blob, fileName);
				});
			} else {
				await zip.generateAsync({ type: 'string' }).then((blob: string) => {
					saveFile(blob, fileName);
				});
			}
		} catch (e) {
			console.log(e);
			setError('Error While Getting Data !');
		} finally {
			setLoadingRightButton(false);
		}
	};

	return (
		<AppDialog
			open={open}
			handleLeftButton={() => setOpen(false)}
			RightButton={
				<LoadingButton
					loading={loadingRightButton}
					loadingPosition="end"
					endIcon={<FileDownload />}
					onClick={generateReport}
				>
					Generate & Export
				</LoadingButton>
			}
			leftButtonLabel="Cancel"
			title={'Download Report'}
			fullWidth={true}
			maxWidth="sm"
		>
			<FormControl style={{ marginTop: '15px', marginBottom: '15px' }}>
				<RadioGroup
					id="exportFormat"
					name="exportFormat"
					row
					value={exportFormat}
					onChange={handleFormatChange}
				>
					<FormControlLabel
						value={ExportFormat.CSV}
						control={<Radio />}
						label="CSV"
					/>
					<FormControlLabel
						value={ExportFormat.PDF}
						control={<Radio />}
						label="PDF"
					/>
				</RadioGroup>
			</FormControl>
			<Typography
				variant="h6"
				sx={{ fontWeight: 'bold', marginBottom: '15px' }}
			>
				Choose Data to Export
			</Typography>
			<FormGroup sx={{ maxHeight: '200px' }}>
				{Object.keys(initialOptions).map((k: string) => (
					<FormControlLabel
						key={k}
						control={<Checkbox />}
						label={options[k]?.title}
						checked={options[k]?.checked}
						onChange={(e) => handleChange(e, k)}
						disabled={
							exportFormat === ExportFormat.PDF &&
							!['comments', 'letters'].includes(k)
						}
					/>
				))}
			</FormGroup>
			{error && (
				<Alert onClose={() => setError('')} severity="error">
					{error}
				</Alert>
			)}
		</AppDialog>
	);
};
