import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROLE_TYPES } from 'constants/roleTypes';
import { USER_TYPES } from 'constants/userTypes';
import Login from 'routes/Login';
import Profile from 'routes/Profile';
import { FourOhFour } from 'routes/404';
import { Layout } from 'routes/Layout';
import { PageBuilder } from 'routes/PageBuilder/PageBuilder';
import ProtectedRoute from 'routes/ProtectedRoute';
import { MyProjects } from 'routes/MyProjects';
import { Users as UsersPage } from 'routes/Users';
import { ProjectCreate } from 'routes/ProjectCreate';
import { PageBuilderRedirect } from 'routes/PageBuilderRedirect';
import { ProjectAdministration } from 'routes/ProjectAdministration';
import { ProjectDetails } from 'routes/ProjectDetails';
import { RestrictedRoute } from 'routes/RestrictedRoute';
import Timeline from 'routes/Timeline';
import InformationPlans from 'routes/InformationPlans';
import { Questions } from 'routes/Questions';
import { FormResponses } from 'routes/FormResponses';
import { PublicBenefits } from 'routes/PublicBenefits';
import Updates from 'routes/Updates';
import Feedback from 'routes/Feedback';
import Comments from 'routes/Comments';
import Analytics from 'routes/Analytics';

interface AllRoutesProps {}

const AllRoutes: FC<AllRoutesProps> = () => {
	return (
		<Routes>
			<Route element={<Layout />}>
				<Route element={<ProtectedRoute />}>
					{/* temporary route */}
					<Route path="/" element={<MyProjects />} />
					<Route
						path="/projects/:projectId/projects"
						element={<MyProjects />}
					/>

					{/* only internal users and developers can access the admin tools */}
					<Route
						element={
							<RestrictedRoute
								allowedUserTypes={[USER_TYPES.INTERNAL, USER_TYPES.DEVELOPER]}
							/>
						}
					>
						<Route
							path="/projects/:projectId/projects-admin"
							element={<ProjectAdministration />}
						/>
						<Route path="/projects/:projectId/users" element={<UsersPage />} />
						<Route
							path="/projects/:projectId/projects/add"
							element={<ProjectCreate />}
						/>
					</Route>

					{/* internal users or developers or admin projects users can access project details */}
					<Route
						element={
							<RestrictedRoute
								allowedUserTypes={[USER_TYPES.INTERNAL, USER_TYPES.DEVELOPER]}
								allowedRoleTypes={[ROLE_TYPES.ADMIN]}
							/>
						}
					>
						<Route path="/projects/:projectId/" element={<ProjectDetails />} />
						<Route
							path="/projects/:projectId/details"
							element={<ProjectDetails />}
						/>
						<Route path="/projects/:projectId/updates" element={<Updates />} />
					</Route>

					{/* internal users or developers or admin project user or content editor project user or layout editor project user can access page builder */}
					<Route
						element={
							<RestrictedRoute
								allowedUserTypes={[USER_TYPES.INTERNAL, USER_TYPES.DEVELOPER]}
								allowedRoleTypes={[
									ROLE_TYPES.ADMIN,
									ROLE_TYPES.CONTENT_EDITOR,
									ROLE_TYPES.LAYOUT_EDITOR,
								]}
							/>
						}
					>
						<Route
							path="/projects/:projectId/timeline"
							element={<Timeline />}
						/>
						<Route
							path="/projects/:projectId/public-benefits"
							element={<PublicBenefits />}
						/>
						<Route
							path="/projects/:projectId/feedback"
							element={<Feedback />}
						/>
						<Route
							path="/projects/:projectId/comments"
							element={<Comments />}
						/>
						<Route
							path="/projects/:projectId/analytics"
							element={<Analytics />}
						/>
						<Route
							path="/projects/:projectId/questions"
							element={<Questions />}
						/>
						<Route
							path="/projects/:projectId/information-plans"
							element={<InformationPlans />}
						/>
						<Route
							path="/projects/:projectId/Responses"
							element={<FormResponses />}
						/>
						<Route
							path="/projects/:projectId/pages"
							element={<PageBuilderRedirect />}
						/>
						<Route
							path="/projects/:projectId/pages/:pageId"
							element={<PageBuilder />}
						/>
					</Route>
					<Route path="/projects/:projectId/profile" element={<Profile />} />
				</Route>
				<Route path="/login" element={<Login />} />
				<Route path="*" element={<FourOhFour />} />
			</Route>
		</Routes>
	);
};

export default AllRoutes;
