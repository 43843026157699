export const initialOptions: any = {
	visitors: { title: 'Visitor Metrics', checked: true },
	sentiments: { title: 'Sentiment Metrics', checked: true },
	feedbacks: { title: 'Feedback Metrics', checked: true },
	followers: { title: 'Follower Metrics', checked: true },
	comments: { title: 'Comment Responses', checked: true },
	polls: { title: 'Poll Responses', checked: true },
	letters: { title: 'Form Responses', checked: true },
};

export const saveFile = (blob: string | Uint8Array, fileName: string) => {
	const url = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.parentNode!.removeChild(link);
};

export const initQuery = `query ($projectId: Int!) {
    analyticsExport(projectId: $projectId) {
        __typename
    }
}`;

export const projectQuery = `query ($projectId: Int!) {
    project(id: $projectId) {
        name
		launchedDate
    }
}`;

export const fileNames: any = {
	visitors: 'visitors',
	sentiments: 'sentiment',
	followers: 'followers-by-date',
	comments: 'comments',
	polls: 'poll-responses',
	letters: 'letters-of-support',
	feedback: 'feedback',
	feedbackByDate: 'feedback-by-date',
};
