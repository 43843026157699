import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Item } from './Item';

interface SentimentCardProps {
	sentimentLevelPercent: number;
	sentimentType: SentimentLevelEnum;
}

// eslint-disable-next-line no-unused-vars
export enum SentimentLevelEnum {
	// eslint-disable-next-line no-unused-vars
	POSITIVE = 'POSITIVE',
	// eslint-disable-next-line no-unused-vars
	NEUTRAL = 'NEUTRAL',
	// eslint-disable-next-line no-unused-vars
	NEGATIVE = 'NEGATIVE',
}

// eslint-disable-next-line no-unused-vars
enum SentimentLevelIconEnum {
	// eslint-disable-next-line no-unused-vars
	POSITIVE = '😄',
	// eslint-disable-next-line no-unused-vars
	NEUTRAL = '😐',
	// eslint-disable-next-line no-unused-vars
	NEGATIVE = '😡',
}

// eslint-disable-next-line no-unused-vars
enum SentimentLevelLabelEnum {
	// eslint-disable-next-line no-unused-vars
	POSITIVE = 'Positive',
	// eslint-disable-next-line no-unused-vars
	NEUTRAL = 'Neutral',
	// eslint-disable-next-line no-unused-vars
	NEGATIVE = 'Negative',
}

export const SentimentCard: React.FC<SentimentCardProps> = (props) => {
	const theme = useTheme();
	const { sentimentLevelPercent, sentimentType } = props;

	return (
		<Item width="254px">
			<Typography
				sx={{
					color: theme.customPalette.textColors.light,
					fontWeight: 800,
				}}
			>
				{`${SentimentLevelLabelEnum[sentimentType]} sentiment`}
			</Typography>
			<Typography
				sx={{
					color: theme.customPalette.textColors.dark,
					fontWeight: 800,
					fontSize: '24px',
					lineHeight: '38px',
					mt: '24px',
				}}
			>
				{`${SentimentLevelIconEnum[sentimentType]} ${
					sentimentLevelPercent || 0
				}%`}
			</Typography>
		</Item>
	);
};
