import { useSnackBar } from 'providers/SnackBarProvider';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import produce from 'immer';
import { Comment } from '../../../types/types';
import { useMutation, useQuery } from 'urql';
import {
	createCommentMutation,
	supportCommentMutation,
	updateCommentMutation,
} from 'api/mutation/comment.mutations';
import { commentsByProjectQuery } from 'api/query/comment.queries';
import useNumberParams from 'hooks/useNumberParams';
import { useUserContext } from 'providers/UserProvider';
import {
	existingComment,
	filterConditions,
	separateComments,
} from 'utility/commentUtils';
import { paginate } from 'utility/paginate';
import { useTheme } from '@mui/material';
import { defaultFilter, Filter } from '../../../utility/commentData';
import _ from 'lodash';
import { SeparatedComment } from '../../../types/separatedComment';
import dayjs from 'dayjs';
import { USER_TYPES } from '../../../constants/userTypes';

export const pageLimit = 10;

const DEFAULT_PAGE_NUMBER = 1;

const useComments = () => {
	const { projectId } = useNumberParams();
	const { user } = useUserContext();
	const { notify } = useSnackBar();

	const [params] = useSearchParams();

	const commentId = params.get('commentId');
	const questionId = params.get('questionId');
	const topicId = params.get('topicId');

	const [{ data, fetching }] = useQuery({
		query: commentsByProjectQuery,
		variables: { projectId },
	});

	const [comments, setComments] = useState([] as Comment[]);
	const [filteredComments, setFilteredComments] = useState([] as Comment[]);
	const [topicQuestionFilteredComments, setTopicQuestionFilteredComments] =
		useState([] as Comment[]);
	const [search, setSearch] = useState('');
	const [filter, setFilter] = useState(defaultFilter);
	const [sortBy, setSortBy] = useState('recentComments');
	const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);

	const semiFilteredSeparatedComments = separateComments(
		topicQuestionFilteredComments,
		sortBy,
		search,
	) as SeparatedComment[];

	const separatedComments = separateComments(
		filteredComments,
		sortBy,
		search,
	) as SeparatedComment[];
	const paginatedComments = paginate(
		separatedComments,
		pageNumber,
		pageLimit,
	) as SeparatedComment[];

	const commentCountPerQuestionId = comments.reduce((obj: any, el) => {
		obj[el.question.id] = (obj[el.question.id] || 0) + 1;
		return obj;
	}, {});

	const theme = useTheme();

	const applyFilters = (commentsToFilter: Comment[]) => {
		const parentComments = commentsToFilter.filter((c) => !c.parentCommentId);

		const filteredComments = commentsToFilter.filter((comment) => {
			return Object.keys(filter).every((filterKey) => {
				let key = filterKey;
				// @ts-ignore
				let value = filter[filterKey];
				if (!value || value === 'all') return true;
				if (filterKey === 'selectedTab') {
					if (['drafted', 'needed', 'responded'].includes(value)) {
						key = 'response';
					} else {
						key = value;
					}
				}
				// @ts-ignore
				return filterConditions[key](
					comment,
					commentsToFilter,
					parentComments,
					// @ts-ignore,
					value,
				);
			});
		});
		const topicQuestionFilteredComments = commentsToFilter.filter((comment) => {
			return Object.keys(_.pick(filter, ['topicId', 'questionId'])).every(
				(filterKey) => {
					// @ts-ignore
					if (!filter[filterKey]) return true;

					// @ts-ignore
					return filterConditions[filterKey](
						comment,
						commentsToFilter,
						parentComments,
						// @ts-ignore,
						filter[filterKey],
					);
				},
			);
		});
		setTopicQuestionFilteredComments(topicQuestionFilteredComments);
		setFilteredComments(filteredComments);
	};

	useEffect(() => {
		if (data?.commentsByProject) {
			//client users are not allow to view rejected comments
			let allowedComments: Comment[] = data.commentsByProject;
			if (user?.userType === USER_TYPES.CLIENT) {
				allowedComments = allowedComments.filter(c => c.approved);
			}
			setComments(allowedComments);
		}
	}, [data?.commentsByProject, user?.userType]);

	useEffect(() => {
		// setFilteredComments(comments);
		applyFilters(comments);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comments, filter]);

	const [, createComment] = useMutation(createCommentMutation);
	const [, supportComment] = useMutation(supportCommentMutation);
	const [{ fetching: updateCommentFetching }, updateComment] = useMutation(
		updateCommentMutation,
	);

	const addComment = (comment: Comment) => {
		setComments(
			produce(comments, (state) => {
				//check if there's already an unsaved comment
				const prevIndex = state.findIndex((c) => c.id === 0);
				if (prevIndex >= 0) {
					state.splice(prevIndex, 1);
				}
				state.push(comment);
			}),
		);
	};

	const saveComment = async (comment: Comment, cancel?: boolean) => {
		if (comment.id !== 0) return 0;

		if (cancel) {
			setComments(
				produce(comments, (state) => {
					state.pop();
				}),
			);
			return 0;
		}

		const res = await createComment({
			newComment: {
				projectId: comment.projectId,
				questionId: comment.question.id,
				parentCommentId: comment.parentCommentId,
				commentText: comment.commentText,
				published: comment.published,
			},
		});

		if (res.error) return 0;

		setComments(
			produce(comments, (state) => {
				const index = state.findIndex((c) => c.id === 0);
				const createdComment = {
					...existingComment(comment),
					...res.data.createComment,
					created: new Date().getTime().toString(),
					approved: true,
					moderatedByUser: user,
					moderated: dayjs().valueOf().toString(),
				};

				if (comment) state[index] = createdComment;
			}),
		);

		notify('Comment saved successfully!');
		return res.data.createComment.id as number;
	};

	const moderateComment = async (comment: Comment, cancel?: boolean) => {
		if (cancel) return 0;
		const existing = {
			id: comment.id,
			commentText: comment.commentText,
			projectId: comment.projectId,
			approved: comment.approved,
			moderated: dayjs().toISOString(),
			moderatedBy: user?.id,
		};
		const res = await updateComment({ existingComment: existing });

		if (res.error) {
			notify('Comment was not saved. Try again later', 'error');
			return 0;
		}

		const index = comments.findIndex((c) => c.id === comment.id);

		setComments(
			produce(comments, (state) => {
				state[index] = {
					...comment,
					...existing,
					moderated: dayjs(existing.moderated).valueOf().toString(),
				};
			}),
		);

		notify('Comment updated successfully!');
		return res.data.updateComment.id as number;
	};

	const editComment = async (comment: Comment, cancel?: boolean) => {
		if (cancel) return;

		const commentToPersist = _.omit(
			comment,
			'mobileUser',
			'mobileUserId',
			'moderatedByUser',
			'moderated',
		);
		const existing = {
			...commentToPersist,
			questionId: comment.question.id,
			created: undefined,
			createdByUser: undefined,
			parentCommentId: undefined,
			question: undefined,
			commentSupports: undefined,
			commentFlags: undefined,
			__typename: undefined,
		};

		const res = await updateComment({ existingComment: existing });

		if (res.error) {
			notify('Comment was not saved. Try again later', 'error');
			return 0;
		}

		const index = comments.findIndex((c) => c.id === comment.id);

		setComments(
			produce(comments, (state) => {
				state[index] = comment;
			}),
		);

		notify('Comment updated successfully!');
		return res.data.updateComment.id as number;
	};

	const likeComment = async (id: number) => {
		const index = comments.findIndex((c) => c.id === id);
		if (index < 0) return;

		const comment = comments[index];
		if (!comment) return;

		// has the user supported yet?
		const supported =
			comment.commentSupports.findIndex((cs) => cs.createdBy === user?.id) >= 0;

		const res = await supportComment({
			supportCommentInput: {
				commentId: comment.id,
				liked: !supported,
			},
		});

		if (res.error) return;

		setComments(
			produce(comments, (state) => {
				if (!supported) {
					if (!user) return;
					state[index].commentSupports.push({
						id: 0, // irrelevant only the userId is useful
						createdBy: user.id || 0,
					});
				} else
					state[index].commentSupports = state[index].commentSupports.filter(
						(c) => c.createdBy !== user?.id,
					);
			}),
		);
	};

	const handleSearch = (value: string) => {
		if (value.length > 0) {
			setFilter(defaultFilter);
			setSearch(value);
		} else setSearch('');
	};

	const handleFilter = (value: Filter) => {
		setFilter(value);
		setPageNumber(DEFAULT_PAGE_NUMBER);
	};

	useEffect(() => {
		const topicIdAsNumber = +topicId!;
		const questionIdAsNumber = +questionId!;

		if (_.isNumber(topicIdAsNumber) && _.isNumber(questionIdAsNumber)) {
			setFilter((prevFilter) => ({
				...prevFilter,
				topicId: topicIdAsNumber,
				questionId: questionIdAsNumber,
			}));
		}
	}, [topicId, questionId]);

	useEffect(() => {
		if (search) {
			setPageNumber(DEFAULT_PAGE_NUMBER);
		}
	}, [search]);

	return {
		projectId,
		user,
		addComment,
		saveComment,
		editComment,
		likeComment,
		moderateComment,
		theme,
		separatedComments,
		paginatedComments,
		search,
		filter,
		pageNumber,
		handleSearch,
		handleFilter,
		setSortBy,
		setPageNumber,
		updateCommentFetching,
		commentId,
		commentCountPerQuestionId,
		isLoading: fetching,
		semiFilteredSeparatedComments,
	};
};

export default useComments;
